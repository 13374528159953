import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .dialog {
    min-width: 370px;
    max-width: 900px;
    width: 95%;
    /* max-height: 75vh; */
    /* height: 80%; */
    /* border-radius: 14px; */
    border-radius: ${({ theme }) => theme.borderRadius};
    border-style: solid;
    border-width: 0;
  }

  @media screen and (max-width: 653px) {
    .dialog {
      /* height: 95%; */
      /* max-height: 80vh; */
      /* height: 95%;
      max-height: 95vh; */
      /* width: 80%; */
    }
  }
`;

export const DivItemPergunta = styled.div`
  /* padding: 2px;
  margin: 2px; */
  /* border: #d8d8d8 1px solid;
  border-radius: 3px; */

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .span-descricao {
    margin-left: 2px;
  }

  .span-descricao-adicional {
    /* border-width: 1; */
    /* border-left: 1px; */
    /* border-top: 0; */
    /* border-bottom: 0; */
    /* border-right: 0; */
    /* border-style: solid; */
    /* border-color: #5555559f; */

    /* padding-left: 3px;

    margin-left: 2px; */

    margin-right: 3px;
  }

  .div-descricao-resposta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background: #e0dbdb9f; */
    /* color: #db2b3c; */
    padding-left: 2px;
    padding-right: 2px;
  }
`;

export const DivItemAdicional = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px 2px 5px;
  /* margin: 2px; */
  height: 55px;
  border-color: ${props => props.theme.colors.backgroundCardapio};
  border-top-width: 1px;
  border-style: solid;
  /* border-radius: 3px; */

  background-color: ${props => props.theme.colors.backgroundCard};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${props => props.theme.fonts.regular};
  font-size: 13px;

  .div-descricao-preco {
    display: flex;
    flex-direction: column;

    margin-left: 2px;
    margin-right: 4px;
    justify-content: space-between;
  }

  //parte antiga
  .div-obs-item {
    flex: 1;
    justify-content: center;

    input[type='checkbox'] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.2); /* IE */
      -moz-transform: scale(1.2); /* FF */
      -webkit-transform: scale(1.2); /* Safari and Chrome */
      -o-transform: scale(1.2); /* Opera */
      transform: scale(1.2);
      padding: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

export const DivItemObservacao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 9px 2px 7px;
  /* margin-left: 2px;
  margin-right: 4px; */

  height: 45px;
  border-color: ${props => props.theme.colors.backgroundCardapio};
  border-top-width: 1px;
  border-style: solid;
  cursor: pointer;

  background-color: ${props => props.theme.colors.backgroundCard};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${props => props.theme.fonts.regular};
  font-size: 13px;
`;

export const DivItemAdicionalObsOld = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px 2px 5px;
  /* margin: 2px; */
  height: 55px;
  border-color: ${props => props.theme.colors.backgroundCardapio};
  border-top-width: 1px;
  border-style: solid;
  /* border-radius: 3px; */

  background-color: ${props => props.theme.colors.backgroundCard};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .div-descricao-preco {
    display: flex;
    width: 100%;
    margin-left: 2px;
    margin-right: 4px;
    justify-content: space-between;
  }

  //parte antiga
  .div-obs-item {
    display: flex;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    align-items: center;
    height: 100%;

    &:hover {
      cursor: pointer;
      background: #ffd;
    }

    input[type='checkbox'] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.2); /* IE */
      -moz-transform: scale(1.2); /* FF */
      -webkit-transform: scale(1.2); /* Safari and Chrome */
      -o-transform: scale(1.2); /* Opera */
      transform: scale(1.2);
      padding: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

export const DivItemPerguntaResposta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px 2px 5px;
  /* margin: 2px; */
  min-height: 55px;
  border-color: ${props => props.theme.colors.backgroundCardapio};
  border-top-width: 1px;
  border-style: solid;
  /* border-radius: 3px; */

  background-color: ${props => props.theme.colors.backgroundCard};

  font-family: ${props => props.theme.fonts.regular};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .div-descricao-preco {
    display: flex;
    width: 100%;
    margin-left: 2px;
    margin-right: 4px;
    justify-content: space-between;
  }
  .span-valor {
    /* margin-left: 22px; */
    /* font-size: 1.1em; */
    font-family: ${props => props.theme.fonts.fontSubttitle};
  }

  //parte antiga

  .div-obs-item {
    display: flex;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    align-items: center;
    height: 100%;

    cursor: pointer;
  }
`;

export const DivBodyDlg = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: column;

  .div-botao-fechar {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 30px;
    z-index: 100;

    .button-close {
      font-size: 30px;
      color: ${props => props.theme.colors.backgroundRodape};
      background-color: ${props => props.theme.colors.backgroundCardapio};

      :hover {
        color: ${props => lighten(0.05, props.theme.colors.backgroundRodape)};
      }

      border-width: 0;
      margin: 0;
      padding: 0;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
  }

  .div-container {
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    /* min-height: 50vh; */

    @media screen and (max-width: 653px) {
      /* max-height: 80vh; */
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .titulo {
    font-family: ${({ theme }) => theme.fonts.fontSubttitle};
    font-size: 13px;
  }

  .titulo-adicionais {
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  .texto-obs {
    background-color: ${props => props.theme.colors.backgroundCardapio};
    border-radius: ${({ theme }) => theme.borderRadiusSmall};
    border-width: 0;
    padding: 5px;
  }

  .texto-obs2 {
    background-color: ${props => props.theme.colors.backgroundCard};
    border-radius: ${({ theme }) => theme.borderRadiusSmall};

    border-width: 0;
    padding: 5px;
  }

  .div-imagem-obs {
    display: flex;
    flex-direction: column;
    flex: 0.7;
    min-width: 308px;
    padding: 20px;
    padding-top: 30px;
    background-color: ${props => props.theme.colors.backgroundCard};

    .image-item {
      aspect-ratio: 1;
      align-self: center;
      width: 100%;
      object-fit: cover;
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    @media screen and (max-width: 686px) {
      min-width: 100%;
      max-height: 200px;
      /* max-width: 200px; */

      flex: 0;
      padding: 10px;
      margin-bottom: 2px;

      display: none;

      .div-observacao-image {
        display: none;
      }

      .image-item {
        width: 180px;
      }
    }
  }

  .div-produto {
    z-index: 9;
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
    min-width: 308px;
    width: 100%;

    .image-produto {
      margin-left: 8px;
      align-self: center;
      height: ${({ theme }) => `${theme.minHeightCardItem - 10}px`};
      width: ${({ theme }) => `${theme.minHeightCardItem - 10}px`};
      object-fit: cover;
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    @media screen and (min-width: 686px) {
      .image-produto,
      .div-observacao-produto {
        display: none;
      }
    }

    @media screen and (max-width: 686px) {
      margin-top: 10px;
      .titulo-produto {
        flex: 1;
        margin-top: 5px;
        margin-bottom: 2px;
      }
    }

    .card-produto {
      display: flex;
      flex-direction: row;
      padding: 10px;
      margin-bottom: 2px;
      font-size: 13px;
      max-height: 165px;
      background-color: ${props => props.theme.colors.backgroundCard};
      border-top-left-radius: ${({ theme }) => theme.borderRadiusSmall};
      border-top-right-radius: ${({ theme }) => theme.borderRadiusSmall};

      overflow: hidden;
      text-overflow: ellipsis;

      .detalhes-produto {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .descricao {
        font-family: ${({ theme }) => theme.fonts.fontSubttitle};
        font-size: 18px;
      }

      .observacao {
        color: ${({ theme }) => theme.colors.description};
      }

      h2 {
        font-size: 18px;
        font-family: 'PoppinsBold';
        color: ${({ theme }) => theme.colors.primary};
      }

      @media screen and (max-width: 686px) {
        max-height: 500px;
      }
    }

    .complementos {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 70vh;
      @media screen and (max-width: 686px) {
        max-height: 46vh;
      }
    }
    .rodape {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      margin-top: 2px;
      height: 60px;
      background-color: ${props => props.theme.colors.backgroundCard};
      border-bottom-left-radius: ${({ theme }) => theme.borderRadiusSmall};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadiusSmall};

      .button-adicionar {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 40px;
        padding: 0 10px 0 10px;
        margin: 0 0 0 10px;
        font-size: 15px;
      }
    }
  }
`;
